import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Answer, Assessment, AssessmentStep, Paginated, Question } from '@haleo-frontend/data-access/models';
import { AppService, EnvironmentUtils } from '@haleo-frontend/utils';
import { AnswerTypeEnum, AssessmentTypeEnum } from '@haleo-frontend/data-access/enums';
import { LanguageService } from './language.service';

@Injectable({
    providedIn: 'root'
})
export abstract class AssessmentService {

    private pages: Map<string, number>;
    private dataStores: Assessment[][];

    constructor(private http: HttpClient,
                private appService: AppService,
                private languageService: LanguageService) {
        this.dataStores = [];
        this.pages = new Map<string, number>();
    }

    getSleepQToComplete(): Observable<Assessment[]> {
        return this.getToComplete(AssessmentTypeEnum.sleepQ);
    }

    getPostQSatisfactionToComplete(): Observable<Assessment[]> {
        return this.getToComplete(AssessmentTypeEnum.postQ);
    }

    getSatisfactionTherapySessionToComplete(): Observable<Assessment[]> {
        return this.getToComplete(AssessmentTypeEnum.satisfactionTherapySession);
    }

    getESSToComplete(): Observable<Assessment[]> {
        return this.getToComplete(AssessmentTypeEnum.ess);
    }

    getScreeners(screenerType = AssessmentTypeEnum.screener): Observable<Assessment[]> {
        return this.http.get<Paginated>(EnvironmentUtils.env.api + `assessments?type=${screenerType}&embed=questions,answers`)
            .pipe(map(data => data.data));
    }

    getLastCompletedScreener(screenerType = AssessmentTypeEnum.screener): Observable<Assessment> {
        return this.http.get<Paginated>(EnvironmentUtils.env.api + `assessments?type=${screenerType}&embed=questions,answers&completed=1&order=desc`)
            .pipe(map(data => {

                if (data.meta.total > 0) {
                    return data.data[0];
                } else {
                    return null;
                }
            }));
    }

    getCompletedThoughtRecordsNextPage(reset = false): Observable<Paginated> {

        const key = 'thought_record_completed';
        if (!reset && (this.pages.get(key) || this.pages.get(key) === 0)) {
            this.pages.set(key, (<number>this.pages.get(key)) + 1);
        } else {
            this.pages.set(key, 1);
            (<any>this.dataStores)[key] = [];
        }

        return this.getCompletedThoughtRecords(<number>this.pages.get(key))
            .pipe(map(data => {
                (<any>this.dataStores)[key].push(...data.data);
                data.data = (<any>this.dataStores)[key];
                return data;
            }));
    }

    getUncompletedThoughtRecords(): Observable<Assessment[]> {
        return this.http.get<Paginated>(EnvironmentUtils.env.api + `assessments?type=${AssessmentTypeEnum.thoughtRecord}&embed=questions,answers&completed=0&order=asc`)
            .pipe(map(data => data.data));
    }

    getCompletedForSleepProfile(): Observable<Assessment[]> {
        return this.http.get<Paginated>(EnvironmentUtils.env.api + `assessments?type=${AssessmentTypeEnum.sleepProfile}&embed=questions,answers&completed=1&order=desc`)
            .pipe(map(data => data.data));
    }

    getAssessment(uuid: string): Observable<Assessment> {
        return this.http.get<Assessment>(EnvironmentUtils.env.api + `assessments/${uuid}?embed=questions,answers&locale=${this.languageService.locale}`);
    }

    sendAnswer(assessment: Assessment, question: Question, answer: Answer, async: boolean = false, isConditionalLast = false) {

        let url = new URL(EnvironmentUtils.env.api + `assessments/${assessment.uuid}/answers/${answer.uuid}`);
        url.searchParams.append('async', async ? '1' : '0');
        url.searchParams.append('isConditionalLast', isConditionalLast ? '1' : '0');
        url.searchParams.append('appKey', this.appService.getAppKey());

        return this.http.put<Answer>(url.href, {questionId: question.id, value: answer.value});
    }

    sendAllAnswers(assessment: Assessment, answers: string) {
        return this.http.post(EnvironmentUtils.env.api + `assessments/${assessment.uuid}/answers`, {
            answers,
            appKey: this.appService.getAppKey()
        });
    }

    createScreener(): Observable<Assessment> {
        return this.http.post<Assessment>(EnvironmentUtils.env.api + 'assessments', {type: AssessmentTypeEnum.screener});
    }

    createThoughtRecord(): Observable<Assessment> {
        return this.http.post<Assessment>(EnvironmentUtils.env.api + 'assessments', {type: AssessmentTypeEnum.thoughtRecord});
    }

    createOrGetWorkingShift(date: string): Observable<Assessment> {
        return this.http.post<Assessment>(EnvironmentUtils.env.api + 'assessments', {
            version: date,
            type: AssessmentTypeEnum.workingShift
        });
    }

    /**
     * Return a list of questions based on the client answers so far.
     */
    buildQuestionList(questions: Question[], steps: AssessmentStep[], currentStep: AssessmentStep | undefined, answers: Answer[], account = {}): Question[] {

        const questionsDisplayed: Question[] = [];

        if (currentStep) {
            let question = questions.find(q => q.key === currentStep.question);

            if (question && question.hide) {
                question = questions.find(q => q.key === currentStep.next);
            }

            if (question && question.answerType !== AnswerTypeEnum.calculated) {

                questionsDisplayed.push(question);
                let nextStep;

                switch (currentStep.type) {
                    case 'question':

                        nextStep = steps.find(s => s.question == currentStep.next);
                        return questionsDisplayed.concat(this.buildQuestionList(questions, steps, nextStep, answers, account));

                    case 'conditional_question':

                        const nextQuestion = this.nextQuestionFromConditionalQuestion(answers, question, currentStep, account);

                        if (nextQuestion) {
                            nextStep = steps.find(s => s.question == nextQuestion.next);
                            return questionsDisplayed.concat(this.buildQuestionList(questions, steps, nextStep, answers, account));
                        } else {
                            return questionsDisplayed;
                        }
                }
            }
        }

        return questionsDisplayed;
    }

    getUnAnsweredQuestions(questions: Question[], answers: Answer[]): Question[] {
        return questions.filter(question => question.answerType !== AnswerTypeEnum.calculated)
            .filter(question => answers.findIndex(answer => answer.questionId === question.id && (!answer.status || answer.status === 'VALID')) < 0);
    }

    /**
     * Return the key of the question that should follow the current question based on the answer and the conditions defined in `step`.
     */
    nextQuestionFromConditionalQuestion(answers: Answer[], currentQuestion: Question, step: AssessmentStep, account: any = {}, questions?: Question[]): { type: string, next: string } {

        const answer = answers.find(a => a.questionId == currentQuestion.id);
        let next = {next: step.defaultNext, type: ''};

        if (answer) {
            if (currentQuestion.answerType === 'checkbox') {

                const questionAnswers = JSON.parse(answer.value);
                step.conditions.forEach(condition => {

                        if (condition.rightValue === 'numberOfItems') {

                            if (this.evaluate(condition, questionAnswers.length)) {
                                next = condition as any;
                            }

                        } else {
                            questionAnswers.forEach((answerValue: any) => {
                                if (this.evaluate(condition, answerValue)) {
                                    next = condition as any;
                                }
                            });
                        }
                    }
                );
            } else {

                for (const condition of step.conditions) {

                    if ('rightValue' in condition) {

                        if (this.evaluate(condition, condition.rightValue)) {
                            next = condition as any;
                        }

                    } else {
                        if (condition.referAnswer) {
                            if (questions) {
                                const q = questions.find(q => q.key === condition.referAnswer)?.id;
                                if (this.evaluate(condition, answers.find(a => a.questionId == q)?.value)) {
                                    next = condition as any;
                                    break;
                                }
                            }
                        } else if (condition.accountField && this.evaluate(condition, account[condition.accountField])) {
                            next = condition as any;
                            break;
                        } else if (this.evaluate(condition, answer.value)) {
                            next = condition as any;
                        }
                    }
                }
            }
        }

        return next;
    }

    getScreenerRecommendation(screener: Assessment) {
        const recommendationQuestion = screener.questions.find(question => question.key === '5_Recommendation');
        const recommendationAnswer = screener.answers.find(answer => answer.questionId === recommendationQuestion?.id);
        let value = recommendationAnswer?.value;

        switch (value) {
            default:
                return value;
            case EnvironmentUtils.env.programKeys.cbtiProgram:
            case EnvironmentUtils.env.programKeys.cbtiNightmareProgram:
                return EnvironmentUtils.env.screenerRecommendations.cbtiProgram;
            case EnvironmentUtils.env.programKeys.cbtiShiftWorkProgram:
            case EnvironmentUtils.env.programKeys.cbtiShiftWorkNightmareProgram:
                return EnvironmentUtils.env.screenerRecommendations.cbtiShiftWorkProgram;
            case EnvironmentUtils.env.programKeys.sleepCoachingProgram:
            case EnvironmentUtils.env.programKeys.sleepCoachingNewParentProgram:
                return EnvironmentUtils.env.screenerRecommendations.sleepCoachingProgram;
        }
    }

    delete(uuid: string): Observable<void> {
        return this.http.delete<void>(EnvironmentUtils.env.api + 'assessments/' + uuid);
    }

    updateSharingStatus(assessment: Assessment, status: boolean): Observable<Assessment> {

        const data = {
            op: 'replace',
            path: '/shared',
            value: status
        };

        return this.http.put<Assessment>(EnvironmentUtils.env.api + 'assessments/' + assessment.uuid, data);
    }

    getWorkingShiftByDate(date: string): Observable<Assessment | null> {
        return this.getWorkingShiftByDates(date, date)
            .pipe(map(data => {
                if (data.length > 0) {
                    return data[0];
                } else {
                    return null;
                }
            }));
    }

    getWorkingShiftByDates(startDate: string, endDate: string): Observable<Assessment[]> {
        const url = `${EnvironmentUtils.env.api}assessments?type=${AssessmentTypeEnum.workingShift}&embed=questions,answers&versionFrom=${startDate}&versionTo=${endDate}`;
        return this.http.get<Paginated>(url)
            .pipe(map(data => data.data));
    }

    getCompletedForSleepHygiene(): Observable<Assessment[]> {
        return this.http.get<Paginated>(EnvironmentUtils.env.api + `assessments?type=${AssessmentTypeEnum.sleepHygiene}&embed=questions,answers&completed=1&order=desc`)
            .pipe(map(data => data.data));
    }

    getCompletedForGoalSetting(): Observable<Assessment[]> {
        return this.http.get<Paginated>(EnvironmentUtils.env.api + `assessments?type=${AssessmentTypeEnum.goalSetting}&embed=questions,answers&completed=1&order=desc`)
            .pipe(map(data => data.data));
    }

    private getCompletedThoughtRecords(page: number): Observable<Paginated> {
        return this.http.get<Paginated>(EnvironmentUtils.env.api + `assessments?type=${AssessmentTypeEnum.thoughtRecord}&embed=questions,answers&completed=1&order=desc&page=${page}`);
    }

    private getToComplete(type: string): Observable<Assessment[]> {
        return this.http.get<Paginated>(EnvironmentUtils.env.api + `assessments?type=${type}&embed=questions,answers&completed=0`)
            .pipe(map(data => data.data));
    }

    private evaluate(condition: any, value: any) {
        return eval(`"${condition.leftValue}"
        ${condition.operator}
        "${value}"`);
    }
}
